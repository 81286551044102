// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/Templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-association-system-intro-js": () => import("./../../../src/Templates/AssociationSystemIntro.js" /* webpackChunkName: "component---src-templates-association-system-intro-js" */),
  "component---src-templates-branding-page-js": () => import("./../../../src/Templates/BrandingPage.js" /* webpackChunkName: "component---src-templates-branding-page-js" */),
  "component---src-templates-concepts-page-js": () => import("./../../../src/Templates/ConceptsPage.js" /* webpackChunkName: "component---src-templates-concepts-page-js" */),
  "component---src-templates-consult-page-js": () => import("./../../../src/Templates/ConsultPage.js" /* webpackChunkName: "component---src-templates-consult-page-js" */),
  "component---src-templates-history-page-js": () => import("./../../../src/Templates/HistoryPage.js" /* webpackChunkName: "component---src-templates-history-page-js" */),
  "component---src-templates-lab-detail-page-js": () => import("./../../../src/Templates/LabDetailPage.js" /* webpackChunkName: "component---src-templates-lab-detail-page-js" */),
  "component---src-templates-labs-page-js": () => import("./../../../src/Templates/LabsPage.js" /* webpackChunkName: "component---src-templates-labs-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/Templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/Templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-project-detail-page-js": () => import("./../../../src/Templates/ProjectDetailPage.js" /* webpackChunkName: "component---src-templates-project-detail-page-js" */),
  "component---src-templates-project-list-page-js": () => import("./../../../src/Templates/ProjectListPage.js" /* webpackChunkName: "component---src-templates-project-list-page-js" */)
}

